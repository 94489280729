import React, { Fragment } from "react";
// import LoaderCSS from "./Loader.module.css";
import { Spinner } from "react-rainbow-components";

const WeibSiteLoader = () => {
  return <img src={""} alt="" />;
};
const Loader = () => {
  return (
    <Fragment>
      <div  >
        <Spinner size="x-large" type="arc" variant="brand">
          <WeibSiteLoader />
        </Spinner>
      </div>
    </Fragment>
  );
};

export default Loader;
