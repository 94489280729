import {createBrowserHistory} from "history";
import rootReducer from "./rootReducer";
import {loadState, saveState} from "./localStorage";
import NetworkServices from "utilities/network-service.js";
import {configureStore} from "@reduxjs/toolkit";
import {createReduxHistoryContext} from "redux-first-history";
export const history = createBrowserHistory();
const {routerMiddleware} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const persistedState = loadState();
const initialState = persistedState;
const enhancers = [];
// const middleware = [thunk, routerMiddleware(history)];
let devToolsExtension = false;
if (process.env.NODE_ENV === "development") {
  devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

// const composedEnhancers = compose( ...enhancers);
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware),
  devTools: devToolsExtension,
  preloadedState: initialState,
});
NetworkServices.setupInterceptors(store, history);
let tempState = {};
if (initialState) {
  tempState = initialState;
}
store.subscribe(() => {
  if (tempState !== store.getState().localStore) {
    saveState({
      localStore: store.getState().localStore,
    });
    tempState = store.getState().localStore;
  }
});
export default store;
