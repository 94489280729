import React from 'react'

export default function Breadcrumb() {
  return (
    <>
         <div className="breadcrumb-section">
            <div className="container">
              <div className="breadcrumb-title text-center">Contact Us</div>
            </div>
          </div>
    </>
  )
}
