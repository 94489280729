import { getFaqs } from "../services/FAQs";
import { listingReducer } from "../reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "faqs",
  getApi: getFaqs,
});

const faqs = reducer;
export { faqs, actions, types };
