import React from 'react'
import { FacebookProvider, CustomChat } from 'react-facebook';

const FacebookMsg = () => {
  return (
      <div style={{ position: 'relative' }}>
    <FacebookProvider appId="738506395044125" chatSupport>
    <CustomChat style={{
      position: 'fixed',
      bottom: '15%',
      right: '20px',
      zIndex: '1000',
    }} pageId="229059440466370" minimized={true}/>
  </FacebookProvider>
      </div>
  )
}

export default FacebookMsg