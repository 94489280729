import { get, post } from "./index";

export const getPackages = (params) => {
  const request = {
    subUrl: `/website/getAllPackages`,
    params,
  };
  return get(request);
};

export const getPackageDetails = (slug) => {
  const request = {
    subUrl: `/website/getPackageDetail/${slug}`,
  };
  return get(request);
};

export const sendInquiry = (data) => {
  const request = {
    subUrl: `/website/inquiry/packageInquiry`,
    data,
  };
  return post(request);
};
