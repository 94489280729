import React, {Fragment} from "react";
const NoTabledata = ({subHeading, btnText, image}) => {
  return (
    <Fragment>
      <div >
        <div>
          {image && <img src={image} alt="No Data"></img>}
          {subHeading && (
            <p className="font-16 line-height-24 weight-500 text-blue margin-top-15">
              {subHeading}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default NoTabledata;
