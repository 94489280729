import React, { useEffect } from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import { useLocation } from "react-router";
import NewHeader from "../components/newheader/Header";
import FacebookMsg from "../components/common/FacebookMsg";

const PageLayout = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    let element = document.getElementById("header-main");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);
  return (
    <>
      {/* <Header PathName={pathname} /> */}
        <NewHeader />
        <FacebookMsg />

      <main className="main-section page-home">{children}</main>
    
      <Footer />
    </>
  );
};
export default PageLayout;
