import { listingReducer } from "../reducers/utils/listingReducer";
import { getAllImageGallery } from "services/imagesGallary";

const { reducer, actions, types } = listingReducer({
  name: "imagesGallary",
  getApi: getAllImageGallery,
});

const imagesGallary = reducer;
export { imagesGallary, actions, types };
