const initialState = {
  token: null,
  user: null,
  tripDetails: null,
  evisaData: null,
  imageGallery: [],
  videoGallery: [],
  blogDetails: null,
};
const actionTypes = {
  CLEAR_LOCAL_STATE: "CLEAR_LOCAL_STATE",
  ON_SET_TRIP_DETAILS: "ON_SET_TRIP_DETAILS",
  ON_SET_EVISA_DATA: "ON_SET_EVISA_DATA",
  ON_SET_IMAGES_DATA: "ON_SET_IMAGES_DATA",
  ON_SET_VIDEO_DATA: "ON_SET_VIDEO_DATA",
  ON_SET_BLOG_DETAIL: "ON_SET_BLOG_DETAIL",
};

const sessionTypes = {
  LOGIN_SUCCESS: "session/LOGIN_SUCCESS",
  LOG_OUT: "session/LOG_OUT",
};
export const localStore = (state = initialState, action) => {
  switch (action.type) {
    case sessionTypes.LOGIN_SUCCESS: {
      const { token, user, reset_pass_token } = action.payload;
      return { ...state, user, token, reset_pass_token };
    }
    case actionTypes.ON_SET_TRIP_DETAILS: {
      return { ...state, tripDetails: action.payload };
    }
    case actionTypes.ON_SET_EVISA_DATA: {
      return { ...state, evisaData: action.payload };
    }
    case actionTypes.ON_SET_IMAGES_DATA: {
      return { ...state, imageGallery: action.payload };
    }
    case actionTypes.ON_SET_VIDEO_DATA: {
      return { ...state, videoGallery: action.payload };
    }
    case actionTypes.ON_SET_BLOG_DETAIL: {
      return { ...state, blogDetails: action.payload };
    }
    case sessionTypes.LOG_OUT:
      return initialState;

    case actionTypes.CLEAR_LOCAL_STATE:
      return { ...initialState };

    default:
      return state;
  }
};
                                          