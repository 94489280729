import React, {useState} from "react";
import AppRoutes from "./AppRoutes";
import ScrollToTop from "react-scroll-to-top";
import ContactFormUsFormGlobal from "../components/common/ContactFormUsFormGlobal";
export default function App() {

  return (
      <div>
          {/* <ScrollToTop smooth color="#146bde"  /> */}
          <AppRoutes/>



      </div>
  );
}
