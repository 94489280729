const initialState = {
   usCanadaPassports:null,
   otherPassports:[],
  };
  export const flightActionTypes = {
    SET_US_CANADA_PASSPORTS:"SET_US_CANADA_PASSPORTS",
    SET_OTHERS_PASSPORTS:"SET_OTHERS_PASSPORTS",

  };
  

  export const flightSearch = (state = initialState, action) => {
    switch (action.type) {
      case flightActionTypes.SET_US_CANADA_PASSPORTS: {
        return { ...state, usCanadaPassports: JSON.stringify(action.payload) };
      }
      case flightActionTypes.SET_OTHERS_PASSPORTS: {
        console.log("action.payload ",action.payload )
        return { ...state, otherPassports: JSON.stringify(action.payload)};
      }
      default:
        return state;
    }
  };
  