/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import RedirectComponent from "../components/RedirectComponent/RedirectComponent";
import RoutesArray from "./RoutesArray";
import RestrictedRoutes from "../hoc/RestrictedRoutes";
import PrivateRoutes from "../hoc/PrivateRoutes";
import Loader from "../components/common/PageLoader";
import PageLayout from "Layouts/PageLayout";
import { useLocation } from "react-router-dom";
import ContactFormUsFormGlobal from "../components/common/ContactFormUsFormGlobal";
const ScrollToTopOnRouteChange = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};
const AppRoutes = (props) => {
  const [open, setOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContactForm(true);
    }, 2000); // Delay of 2000ms (2 seconds)

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
      <Fragment>
        <BrowserRouter>
          <ScrollToTopOnRouteChange/>
          <Routes>
            {RoutesArray.map((route, index) => {
              let ComponentName = route.element;
              if (route.isPrivateRoute) {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                          <React.Suspense fallback={<Loader/>}>
                            <PrivateRoutes>
                              <ComponentName/>
                            </PrivateRoutes>
                          </React.Suspense>
                        }
                    />
                );
              }

              if (route.isRestrictedRoute) {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                          <React.Suspense fallback={<Loader/>}>
                            <RestrictedRoutes>
                              <ComponentName/>
                            </RestrictedRoutes>
                          </React.Suspense>
                        }
                    />
                );
              }

              if (route.isOpenRoute) {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                          <React.Suspense fallback={<Loader/>}>
                            <PageLayout>
                              <ComponentName/>
                            </PageLayout>
                          </React.Suspense>
                        }
                    />
                );
              }
              return null;
            })}
            <Route exact path="*" element={<RedirectComponent/>}/>
          </Routes>
        </BrowserRouter>
        {showContactForm && (
            <div className="customqueryform">
              <p className="customqueryform-specificpara" onClick={() => setOpen(!open)}>
                Contact Us
              </p>
              <ContactFormUsFormGlobal open={open} setOpen={setOpen}  />
            </div>
        )}
      </Fragment>
  );
};

export default AppRoutes;
